<template>
  <v-container>
    <!-- notifications starts here -->

    <v-alert v-show="pageIsError" transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px" color="error" type="error">
      An error occurred!
    </v-alert>

    <v-dialog
      v-model="openDialog"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-text class="pt-5 text-h6 text-center">
          <v-icon class="text-h3" color="orange darken-2">mdi-alert-outline</v-icon>
          
          <div class="mt-5">
            Would you agree to use the existing objectives on this stage?
          </div> 
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal lighten-1"
            text
            @click="stage_item_choice('New')"
          >
            Disagree
          </v-btn>
          <v-btn
            color="teal lighten-1"
            text
            @click="stage_item_choice('Existing')"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- notifications ends here -->

    <v-row class="mb-5">
      <v-col class="d-flex align-center">
        Currently viewing:
        <v-select v-model="selectedView" :items="view" class="ml-2 text-subtitle-1" style="max-width: 220px"
          hide-details="auto" dense solo @change="readKanbanData" />
      </v-col>

      <v-col class="d-flex justify-end align-center">
        <v-icon class="text-h4" title="Manage Stages" @click="
          $router.push({
            path: `/dashboard/admin/kanban/manage_stages/${selectedView}`,
          })
        ">
          mdi-cog
        </v-icon>
      </v-col>
    </v-row>

    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <div class="stage-row d-flex pb-2" v-for="row in rows" :key="row.id">
      <div class="mr-2 bordered pa-0" cols="2" v-for="column in row.columns" :key="column.id">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <center>{{ column.stageName }} ({{ column.list.length }})</center>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="stage-description">
              <center>{{ column.stageDescription }}</center>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div v-if="!manageKanban" class="draggable-container">
          <draggable class="px-2" style="min-height: 100%" :list="column.list" group="items" @change="updateList($event, column.id)">
            <div class="list-item my-5 rounded-lg" v-for="(item, index) in column.list" :key="index">
              <v-card v-if="selectedView === 'Deal'" style="background-color: #1b1b1b; border: 1px solid #161616;" min-width="500" elevation="5">
                <v-card-text>
                  <div> 
                    <h3> <v-icon>mdi-account</v-icon> Customers: </h3>
                     
                    <v-divider />

                    <h3 v-if="item.customers.length === 0"> None </h3>

                    <v-list-item v-for="customer in item.customers" :key="customer.id" >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ (customer.last_name ) }}, {{ (customer.first_name) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card-text>


                <v-card-text>
                  <div>
                    <h3> <v-icon>mdi-file-document</v-icon> Deal Summaries: </h3>

                    <v-divider />

                    <h3 v-if="item.deal_summaries.length === 0"> None </h3>
                    
                    <v-list-item v-for="deal_summary in item.deal_summaries" :key="deal_summary.id">
                      <v-list-item-content>
                        <v-list-item-title>
                          PV: {{ (formatValue(deal_summary.property_value, 'currency')) }} - OA: {{ (formatValue(deal_summary.owing_against, 'currency')) }} - AR: {{ (formatValue(deal_summary.amount_requested, 'currency')) }} - {{ (readMortgageePosition(deal_summary.mortgage_position)) }}
                        </v-list-item-title>

                        <v-list-item-title>
                          LTV: {{ formatValue(deal_summary.loan_to_value, 'percent') }} - Term: {{ (formatValue(deal_summary.proposed_term, 'percent')) }} - Rate: {{ formatValue(deal_summary.proposed_rate, 'percent') }} - Fee: {{ (formatValue(deal_summary.proposed_lender_fee, 'percent')) }}
                        </v-list-item-title>

                        <v-list-item-title>
                            {{ (deal_summary.city ) }}, {{ (readProvinceCode(deal_summary.province)) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <div>
                    <v-btn title="Number of days" text> 
                      <v-icon>mdi-white-balance-sunny</v-icon> Day count: {{ item.item_day_count }}
                    </v-btn>

                    <v-btn title="View Objectives" @click="viewObjectiveList(item.stg_itm_id)" text> 
                      <v-icon>mdi-bullseye-arrow</v-icon> Objective: {{ item.objectives_count }}
                    </v-btn>
                    
                    <v-btn @click="viewItem(item.id)" text>
                      <v-icon>mdi-magnify</v-icon> {{ selectedView === 'Deal' ? 'View Deal' : 'View Lender' }}
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>

              <v-card v-if="selectedView === 'Lender on boarding'" style="background-color: #1b1b1b; border: 1px solid #161616;" min-width="505" elevation="5">
                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-text>
                  Contact Number: {{ item.phone }} <br />
                  Website: {{ item.website }}
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <div>
                    <v-btn title="Number of days" text> 
                      <v-icon>mdi-white-balance-sunny</v-icon> Day count: {{ item.item_day_count }}
                    </v-btn>

                    <v-btn title="View Objectives" @click="viewObjectiveList(item.stg_itm_id)" text> 
                      <v-icon>mdi-bullseye-arrow</v-icon> Objective: {{ item.objectives_count }}
                    </v-btn>
                    
                    <v-btn @click="viewItem(item.id)" text>
                      <v-icon>mdi-magnify</v-icon> {{ selectedView === 'Deal' ? 'View Deal' : 'View Lender' }}
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import API from '@/plugins/API';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },

  data: () => ({
    newItemName: '',
    itemDayCount: null,
    objectivesCount: null,
    openDialog: false,
    stg_itm_id: null,
    pageIsLoading: false,
    pageIsError: false,
    manageKanban: false,
    selectedView: 'Deal',

    temp_stage_id: null,
    temp_item_id: null,

    view: ['Deal', 'Lender on boarding'],

    stg_itms: [],

    rows: [
      {
        id: 1,

        columns: [],
      },
    ],
  }),

  computed: {
    userInfo() {
      return this.$store.getters['Auth/getAuthUser'];
    },
  },

  methods: {
    readProvinceCode(province_code) {
      switch(province_code) {
        case 'AB':
          return 'Alberta';

        case 'BC':
          return 'British Columbia';

        case 'ON':
          return 'Ontario';

        default: 
          return 'No province'
      }
    },

    readMortgageePosition(position) {
      switch(position) {
        case 1: 
          return '1st';
        case 2: 
          return '2nd';
        case 3: 
          return '3rd';
        case 4: 
          return '4th';
        default:
          return 'Position not found'
      }
    },

    viewItem(id) {
      if (this.selectedView === 'Deal')
        this.$router.push({ path: `/dashboard/admin/deal/read/${id}` });

      if (this.selectedView === 'Lender on boarding')
        this.$router.push({ path: `/dashboard/admin/company/read/${id}` });
    },

    viewObjectiveList(id) {
      this.$router.push({ path: `/dashboard/admin/kanban/${this.selectedView}/stage-item/${id}/objective/list/` });
    },

    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },
    
    formatValue(x, format) {
      const value = Number(x);

      switch (format) {
        case 'currency':
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        case 'percent':
          return value + '%';
        default:
          return value;
      }
    },

    async stage_item_choice(choice){
      try {
        const res = await API().post('api/kanban/update_kanban_stage_item', {
          stage_id: this.temp_stage_id,
          item_id: this.temp_item_id,
          type: this.selectedView,
          choice: choice,
        });

        console.log(res);

        this.rows[0].columns.forEach(column => {

          if(column.id === res.data.kbn_stg.id) {

            const [ stg_itm ] = res.data.stg_itms.filter(stg_itm => stg_itm.kbn_stg.id === res.data.kbn_stg.id);

            column.list.forEach(list_item => {
              if(list_item.id === res.data.id) {
                list_item.stg_itm_id = stg_itm.id;
                list_item.item_day_count = this.getDays(stg_itm.createdAt);
                list_item.objectives_count = stg_itm.stg_objs.length;
              }
            })
          }
        })
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      } finally {
        this.openDialog = false;
      }
    },

    async updateList(event, stageId) {
      if (event.added) {
        console.log(`${event.added.element.id} is added to the stage id: ${stageId}`);

        try {
          let res = null;

          if(this.selectedView === 'Deal') {
            res = await API().get(`api/kanban/read_deal_stage_item?deal_id=${event.added.element.id}`);
          }

          if(this.selectedView === 'Lender on boarding') {
            res = await API().get(`api/kanban/read_lender_stage_item?company_id=${event.added.element.id}`);
          }
          
          // check if a stage item already exist
          const existing = res.data.stg_itms.some(
              (item) => item.kbn_stg.id === stageId
            );
          
          if (existing) {
            this.openDialog = true;
            this.temp_stage_id = stageId;
            this.temp_item_id = event.added.element.id;
          }

          if (!existing) {
            this.temp_stage_id = stageId;
            this.temp_item_id = event.added.element.id;
            this.stage_item_choice('New');
          }
          
        } catch (error) {
          console.log(error);
          this.pageIsError = true;
          setTimeout(() => {
            this.pageIsError = false;
          }, 2000);
        }
      }

      if (event.removed) {
        console.log(
          `${event.removed.element.id} is removed to the stage id: ${stageId}`
        );
      }
    },

    async readKanbanData() {
      try {
        this.pageIsLoading = true;
        this.rows[0].columns = [];

        if (this.selectedView === 'Lender on boarding') {
          const res = await API().get(`api/kanban/get_kanban_lender_data`);
          const stages = res.data;

          console.log(res)

          stages.forEach((stage) => {
            const column = {
              id: stage.id,
              stageName: stage.stage_name,
              stageDescription: stage.stage_description,
              list: [],
            };

            // store into the list 
            stage.companies.forEach(async (company) => {
              const [ stg_itm ] = company.stg_itms.filter(stg_itm => stg_itm.kbn_stg.id === stage.id);

              if(stg_itm !== undefined) {
                this.stg_itm_id = stg_itm.id;
                this.itemDayCount = this.getDays(stg_itm.createdAt);
                this.objectivesCount = stg_itm.stg_objs.length;
              }
              
              if(stg_itm === undefined) {
                const res = await API().post('api/kanban/update_kanban_stage_item', {
                  stage_id: stage.id,
                  item_id: company.id,
                  type: this.selectedView,
                });

                console.log('stg_itm added: ', res.data)

                const stg_itms = res.data.stg_itms;
                const [ stg_itm ] = stg_itms.filter(stg_itm => stg_itm.kbn_stg.id === stage.id);
                
                this.stg_itm_id = stg_itm.id;
                this.itemDayCount = this.getDays(stg_itm.createdAt);
                this.objectivesCount = stg_itm.stg_objs.length;
              }
              
              column.list.push({
                  id: company.id,
                  name: company.name,
                  phone: company.phone,
                  website: company.website,
                  stg_itm_id: this.stg_itm_id,
                  item_day_count: this.itemDayCount,
                  objectives_count: this.objectivesCount
                });
            });

            this.rows[0].columns.push(column);
          });
        }

        if (this.selectedView === 'Deal') {
          const res = await API().get(`api/kanban/get_kanban_deal_data`);
          const stages = res.data;
          
          console.log('stages', res)

          stages.forEach((stage) => {
            const column = {
              id: stage.id,
              stageName: stage.stage_name,
              stageDescription: stage.stage_description,
              list: [],
            };

            // store 
            stage.deals.forEach(async (deal) => {
              const [ stg_itm ] = deal.stg_itms.filter(stg_itm => stg_itm.kbn_stg.id === stage.id);

              if(stg_itm !== undefined) {
                this.stg_itm_id = stg_itm.id;
                this.itemDayCount = this.getDays(stg_itm.createdAt);
                this.objectivesCount = stg_itm.stg_objs.length;
              }
              
              if(stg_itm === undefined) {
                const res = await API().post('api/kanban/update_kanban_stage_item', {
                  stage_id: stage.id,
                  item_id: deal.id,
                  type: this.selectedView,
                  choice: 'New',
                });

                console.log('stg_itm added: ', res.data)

                const stg_itms = res.data.stg_itms;
                const [ stg_itm ] = stg_itms.filter(stg_itm => stg_itm.kbn_stg.id === stage.id);
                
                this.stg_itm_id = stg_itm.id;
                this.itemDayCount = this.getDays(stg_itm.createdAt);
                this.objectivesCount = stg_itm.stg_objs.length;
              }
              
              column.list.push({
                id: deal.id,
                customers: deal.customers,
                deal_summaries: deal.deal_summaries,
                internal_name: deal.internal_name,  
                item_day_count: this.itemDayCount,
                stg_itm_id: this.stg_itm_id,
                objectives_count: this.objectivesCount,
              });
            });

            this.rows[0].columns.push(column);
          });
        }

        this.pageIsLoading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },

  mounted() {
    this.readKanbanData();
  },
};
</script>

<style lang="scss">
.stage-row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .draggable-container {
    height: 700px; 
    overflow-y: auto; 
    overflow-x: hidden;

    .list-item {
      position: relative;
    }
  }
}

.stage-description {
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 350px;
}

/* height */
.stage-row::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.stage-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.stage-row::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.stage-row::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}


/* height */
.draggable-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.draggable-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.draggable-container::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.draggable-container::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}

.circle .v-input--selection-controls__ripple {
  border-radius: 50%;
}

.circle .v-input--selection-controls__input {
  border-radius: 50%;
}

.circle .v-icon {
  border-radius: 50%;
}
</style>
